import { DownOutlined, ReloadOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Menu,
    MenuProps,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RocketApi from "../../../utils/api/rocket-api";
import ModalCommands from "../../Dashboard/ui/modalCommands";

const { Text } = Typography;

interface DataType {
    id: string;
    description: string;
    args: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

const Commands = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [data, setData] = useState<any[]>();
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const api = new RocketApi();
    const { t } = useTranslation();

    const fetchData = async (controller: AbortController): Promise<void> => {
        try {
            setLoading(true);
            const response = await api.request("commands", {
                method: "GET",
                controller,
            });
            const data = await response?.json();
            setData(data.items);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const showModal = (e: any, row: any, editing: boolean) => {
        e.preventDefault();
        e.stopPropagation();
        //clone
        if (row) {
            setSelectedRow(row);
        }
        setEditing(editing);
        setIsModalVisible(true);
    };

    const reloadTableActions = () => {
        const controller = new AbortController();
        fetchData(controller);
        return () => controller.abort();
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "ID",
            key: "id",
            dataIndex: "id",
            sorter: (a, b) => a.id.localeCompare(b.id),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: t("admin.commands.tableTitles.description"),
            key: "description",
            dataIndex: "description",
            sorter: (a, b) => a.description.localeCompare(b.description),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Args",
            key: "args",
            dataIndex: "args",
            render: (value, row, index) => {
                return value.join(",");
            },
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (value, row, index) => {
                let color: string = "#4CAF50";
                switch (value) {
                    case "PROCESSING":
                        color = "#2196F3";
                        break;

                    case "ERROR":
                        color = "#F44336";
                        break;

                    default:
                        break;
                }

                return (
                    <div>
                        <p
                            style={{
                                color: color,
                                margin: "0",
                                fontWeight: "bold",
                            }}
                        >
                            {t(`admin.commands.status.${value}`)}
                        </p>
                    </div>
                );
            },
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: t("admin.commands.tableTitles.createdAt"),
            key: "createdAt",
            dataIndex: "createdAt",
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
            sortDirections: ["descend", "ascend"],
            defaultSortOrder: "descend",
        },
        {
            title: t("admin.commands.tableTitles.updatedAt"),
            key: "updatedAt",
            dataIndex: "updatedAt",
            sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
            sortDirections: ["descend", "ascend"],
            defaultSortOrder: "descend",
        },
        {
            title: t("admin.commands.tableTitles.actions"),
            dataIndex: "additionalActions",
            key: "additionalActions",
            render: (value, row, index) => {
                const items: MenuProps["items"] = [
                    {
                        key: "1",
                        label: (
                            <Button
                                type="link"
                                onClick={(e) => showModal(e, row, true)}
                            >
                                {t("admin.commands.btns.manageCommands")}
                            </Button>
                        ),
                    },
                ];

                return (
                    <Dropdown menu={{ items }} trigger={["hover"]}>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Space>
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const refresh = () => {
        setRefreshIndex((prev) => !prev);
    };

    useEffect(() => {
        const controller = new AbortController();

        fetchData(controller);

        return () => {
            controller.abort();
        };
    }, [refreshIndex]);

    return (
        <>
            <ModalCommands
                open={isModalVisible}
                onClose={setIsModalVisible}
                rowData={selectedRow}
                editing={editing}
                reloadTable={reloadTableActions}
                commands={data}
            />
            <Row justify="space-between" align="middle">
                <Col>
                    <Text strong>Commands</Text>
                </Col>
                <Col>
                    <Tooltip title={t("errorPages.btns.reload")}>
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={() => refresh()}
                            style={{ marginRight: 10 }}
                        ></Button>
                    </Tooltip>
                    <Button
                        onClick={(e) => showModal(e, undefined, false)}
                        type="primary"
                    >
                        {t("shared.create")}
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Table
                columns={columns}
                dataSource={data}
                rowKey={(row) => `${row.id}-${row.updatedAt}`}
                pagination={{
                    pageSize: 15,
                }}
                loading={loading}
                scroll={{ x: 1000 }}
            />
        </>
    );
};

export default Commands;
