import {
    AmazonCircleFilled,
    CaretLeftOutlined,
    CaretRightOutlined,
    CodeOutlined,
    DatabaseFilled,
    FilterFilled,
    FilterOutlined,
    FundOutlined,
    GoogleCircleFilled,
    IdcardFilled,
    InfoCircleFilled,
    LogoutOutlined,
    PercentageOutlined,
    SettingFilled,
    WindowsFilled,
    WindowsOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
    Button,
    Col,
    Layout,
    Menu,
    Row,
    Select,
    Tooltip,
    Tour,
    Typography,
    message,
} from "antd";
import type { TourProps } from "antd";
import { Formik } from "formik";
import {
    createElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
    Link,
    Navigate,
    Outlet,
    useLocation,
    useNavigate,
} from "react-router-dom";

import RenderState from "../components/debug";
import { tourSteps } from "../components/rocket/tour/steps";
import {
    InitialValues,
    defaultInitialValues,
} from "../components/rocket/types";
import Loading from "../components/ui/Loading";
import { BUILD_TIME, LONG_COMMIT, SHORT_COMMIT, VERSION } from "../constant";
import { getUserLocalStorage, useAuth } from "../context/AuthContext";
import { useTitleSettings } from "../context/TitleContext";
import useNamedRef from "../hooks/useNamedRef";

const { Header, Sider, Content, Footer } = Layout;
const { Text, Title } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

type MenuItemWithAuthZ = MenuItem & {
    features?: string[];
    shallowcheckfeatures: string;
    children?: MenuItemWithAuthZ[];
};

const filterMenuItemsByFeatures = (
    embed: boolean,
    allMenuItems: MenuItemWithAuthZ[],
    features: string[],
): MenuItemWithAuthZ[] => {
    const filter = (item: MenuItemWithAuthZ): boolean => {
        if (item.key == "/app/logout" && embed) {
            return false; // remove logout button when embed
        }
        if (!item.features || item.features.length === 0) {
            return true; // include items that don't have any features specified
        }

        if (item.shallowcheckfeatures) {
            if (item.features.some((f) => features.includes(f))) {
                return true; // include item if it matches at least one of the specified features
            }
        } else {
            if (item.features.every((f) => features.includes(f))) {
                return true; // include item if it matches all of the specified features
            }
        }

        return false; // exclude item if it doesn't match any of the specified features and doesn't have any child menu items
    };

    const reduceMenuItems = (items: MenuItemWithAuthZ[]): MenuItemWithAuthZ[] =>
        items.reduce(
            (acc: MenuItemWithAuthZ[], menuItem: MenuItemWithAuthZ) => {
                const shouldRender = filter(menuItem);

                if (!shouldRender) return acc;

                if (menuItem.children) {
                    const children = reduceMenuItems(menuItem.children);
                    menuItem.children = children;
                }

                return acc.concat(menuItem);
            },
            [],
        );

    return reduceMenuItems(allMenuItems);
};

const AppLayout = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { isAuthenticated, isLoading, logout, user } = useAuth();
    const { filterEnabled, title, lastUpdated, filterComponent } =
        useTitleSettings();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState<boolean>(false);

    const [filterCollapsed, setFilterCollapsed] = useState(true);
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [selected, setSelected] = useState<any>(undefined);
    const [selectedLanguage, setSelectedLanguage] = useState<
        string | undefined
    >(i18n.language);

    const embed = localStorage.getItem("embed") === "true";

    //filter
    const refFilter = useNamedRef("filters");

    //indicators values
    const refValuesIndicators = useNamedRef("valuesIndicators");
    const refValuesIndicatorsPrev = useNamedRef("valuesIndicatorsPrev");
    const refValuesIndicatorsActual = useNamedRef("valuesIndicatorsActual");
    const refValuesIndicatorsForecast = useNamedRef("valuesIndicatorsForecast");

    const refQuantityIndicators = useNamedRef("quantityIndicators");
    const refQuantityIndicatorsPrev = useNamedRef("quantityIndicatorsPrev");
    const refQuantityIndicatorsActual = useNamedRef("quantityIndicatorsActual");

    const refUsages = useNamedRef("usages");
    const refViewUsages = useNamedRef("viewUsages");
    const refDateUsages = useNamedRef("dateUsages");
    const refDownloadUsages = useNamedRef("downloadUsages");

    const refByMonth = useNamedRef("byMonth");
    const refDownloadByMonth = useNamedRef("downloadByMonth");

    const refResources = useNamedRef("resources");
    const refByResources = useNamedRef("byResources");
    const refByCategory = useNamedRef("byCategory");
    const refByRegion = useNamedRef("byRegion");
    const refByTags = useNamedRef("byTags");
    const refByPublisher = useNamedRef("byPublisher");
    const refByVms = useNamedRef("byVms");

    const refByDay = useNamedRef("byDay");
    const refDownloadByDay = useNamedRef("downloadByDay");

    const refReports = useNamedRef("reports");
    const refDownloadReports = useNamedRef("downloadReports");
    const refReloadReports = useNamedRef("reloadReports");

    const references = {
        refFilter,
        refValuesIndicators,
        refValuesIndicatorsPrev,
        refValuesIndicatorsActual,
        refValuesIndicatorsForecast,
        refQuantityIndicators,
        refQuantityIndicatorsPrev,
        refQuantityIndicatorsActual,
        refUsages,
        refDateUsages,
        refViewUsages,
        refDownloadUsages,
        refByMonth,
        refDownloadByMonth,
        refResources,
        refByResources,
        refByCategory,
        refByRegion,
        refByTags,
        refByPublisher,
        refByVms,
        refByDay,
        refDownloadByDay,
        refReports,
        refDownloadReports,
        refReloadReports,
    };

    //@ts-ignore
    const steps: TourProps["steps"] = tourSteps(t, references);
    localStorage.setItem("step", "0");

    const format: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    };

    const [lastUpdate, setLastUpdate] = useState(
        new Date(lastUpdated).toLocaleString(i18n.language, format),
    );

    useEffect(() => {
        const formattedDate = new Date(lastUpdate).toLocaleString(
            i18n.language,
            format,
        );
        setLastUpdate(formattedDate);
    }, [i18n.language]);

    const dataVersion = getUserLocalStorage()!;

    const allMenuItems = useMemo(
        () =>
            [
                {
                    label: <Link to={"/app"}>Home</Link>,
                    key: "/app",
                    icon: <FundOutlined />,
                    // HACK: We only show Indicators if user has an associated cloud
                    features: ["azure", "aws", "gcp", "azuredw"],
                    shallowcheckfeatures: "true",
                },
                {
                    label: <Link to={"/app/gcp"}>Google</Link>,
                    key: "/app/gcp",
                    icon: <GoogleCircleFilled />,
                    features: ["gcp"],
                },
                {
                    label: <Link to={"/app/aws"}>AWS</Link>,
                    key: "/app/aws",
                    icon: <AmazonCircleFilled />,
                    features: ["aws"],
                },
                {
                    label: <Link to={"/app/azure"}>Azure</Link>,
                    key: "/app/azure",
                    icon: <WindowsFilled />,
                    features: ["azure"],
                },
                {
                    label: <Link to={"/app/azuredw"}>Azure</Link>,
                    key: "/app/azuredw",
                    icon: <WindowsFilled />,
                    features: ["azuredw"],
                },
                {
                    label: <Link to={"/app/ibm"}>IBM</Link>,
                    key: "/app/ibm",
                    icon: <DatabaseFilled />,
                    features: ["ibm"],
                },
                {
                    label: "Admin",
                    key: "/app/admin",
                    icon: <IdcardFilled />,
                    children: [
                        {
                            label: (
                                <Link to={"/app/admin/users"}>
                                    {t("admin.users.title")}
                                </Link>
                            ),
                            key: "/app/admin/users",
                            icon: <IdcardFilled />,
                            features: ["admin"],
                        },
                        {
                            label: (
                                <Link to={"/app/admin/commands"}>
                                    {t("admin.commands.title")}
                                </Link>
                            ),
                            key: "/app/admin/commands",
                            icon: <CodeOutlined />,
                            features: ["admin"],
                        },
                        {
                            label: "Markups",
                            key: "/app/admin/markups",
                            icon: <PercentageOutlined />,
                            children: [
                                {
                                    label: (
                                        <Link to={"/app/admin/markups/azure"}>
                                            Azure
                                        </Link>
                                    ),
                                    key: "/app/admin/markups/azure",
                                    icon: <WindowsFilled />,
                                    features: ["azure"],
                                },
                                {
                                    label: (
                                        <Link to={"/app/admin/markups/azuredw"}>
                                            Azure DW
                                        </Link>
                                    ),
                                    key: "/app/admin/markups/azuredw",
                                    icon: <WindowsOutlined />,
                                    features: ["azuredw"],
                                },
                                {
                                    label: (
                                        <Link to={"/app/admin/markups/aws"}>
                                            AWS
                                        </Link>
                                    ),
                                    key: "/app/admin/markups/aws",
                                    icon: <AmazonCircleFilled />,
                                    features: ["aws"],
                                },
                                {
                                    label: (
                                        <Link to={"/app/admin/markups/gcp"}>
                                            Google
                                        </Link>
                                    ),
                                    key: "/app/admin/markups/gcp",
                                    icon: <GoogleCircleFilled />,
                                    features: ["gcp"],
                                },
                            ],
                            features: ["admin"],
                        },
                    ],
                    features: ["admin"],
                },

                {
                    label: (
                        <Link to={"/app/settings"}>
                            {t("admin.settings.title")}
                        </Link>
                    ),
                    key: "/app/settings",
                    icon: <SettingFilled />,
                    hidden: true,
                },
                {
                    label: t("app.menu.logout"),
                    key: "/app/logout",
                    icon: <LogoutOutlined />,
                    onClick: () => {
                        caches.keys().then((names) => {
                            names.forEach((name) => {
                                caches.delete(name);
                            });
                        });

                        logout().then(() => navigate("/login"));
                    },
                },
            ] as MenuItemWithAuthZ[],
        [t],
    );

    const filteredMenuItems = useCallback(() => {
        return filterMenuItemsByFeatures(
            embed,
            allMenuItems,
            user?.features || [],
        );
    }, [allMenuItems, embed, user?.features]);

    useEffect(() => {
        setSelected(location.pathname);
    }, [location]);

    const options = [
        { label: "Português", value: "pt-BR" },
        { label: "English", value: "en-US" },
        { label: "Español", value: "es-MX" },
        { label: "日本語", value: "ja-JP" },
    ];

    const selectedOption = options.find(
        (option) => option.value === selectedLanguage,
    );

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (isLoading) {
        return <Loading />;
    }

    const initialValues: InitialValues = defaultInitialValues;

    function copyToClipBoard(e: any, record: any) {
        e.preventDefault();
        e.stopPropagation();

        navigator.clipboard
            .writeText(
                `${record.version}-${record.shortCommit}-${record.buildTime}`,
            )
            .then(() => {
                messageApi.open({
                    type: "success",
                    content: `${t("shared.copied")} ${record.version}-${
                        record.shortCommit
                    }-${record.buildTime}`,
                });
            })
            .catch((error) => {
                messageApi.open({
                    type: "warning",
                    content: `${t("app.footer.messages.failed")}: ${
                        record.version
                    }-${record.shortCommit}-${record.buildTime}`,
                });
                console.log("Error copying to clipboard:", error);
            });
    }

    const handleLanguageChange = (language: string | undefined) => {
        if (language === undefined) {
            return;
        }

        i18n.changeLanguage(language);
        setSelectedLanguage(language);

        const url = new URL(window.location.href);
        url.searchParams.set("lang", language);

        window.history.pushState({ path: url.href }, "", url.href);
    };

    return (
        <>
            <Formik onSubmit={() => {}} initialValues={initialValues}>
                <Layout
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <Sider
                        collapsedWidth={0}
                        trigger={null}
                        collapsible
                        collapsed={siderCollapsed}
                        className="site-layout-background menu-aside"
                    >
                        <div className="logo" />

                        <Menu
                            theme="dark"
                            mode="inline"
                            selectedKeys={[selected]}
                            items={filteredMenuItems()}
                        />
                    </Sider>

                    <Layout className="site-layout">
                        <Header
                            className="site-layout-background"
                            style={{
                                padding: 0,
                            }}
                        >
                            <Row justify="space-between" align="middle">
                                <Col
                                    xs={12}
                                    xl={16}
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                    }}
                                >
                                    <Col
                                        xs={4}
                                        xl={2}
                                        className={`sider-btn ${
                                            siderCollapsed
                                                ? "collapsed"
                                                : "no-collapsed"
                                        }`}
                                    >
                                        {createElement(
                                            siderCollapsed
                                                ? CaretRightOutlined
                                                : CaretLeftOutlined,
                                            {
                                                className: "trigger",
                                                onClick: () =>
                                                    setSiderCollapsed(
                                                        !siderCollapsed,
                                                    ),
                                            },
                                        )}
                                    </Col>

                                    {location.pathname !== "/app" && (
                                        <Col
                                            xs={16}
                                            xl={
                                                !siderCollapsed ||
                                                !filterCollapsed
                                                    ? 16
                                                    : 16
                                            }
                                            className={`info-header ${
                                                siderCollapsed ||
                                                filterCollapsed
                                                    ? "collapsed"
                                                    : "no-collapsed"
                                            }`}
                                        >
                                            <Title className="title-page">
                                                {title}
                                            </Title>
                                            <Text className="last-update">
                                                {t("app.header.lastUpdate")}:{" "}
                                                {lastUpdate}
                                            </Text>
                                        </Col>
                                    )}
                                </Col>
                                {filterEnabled && (
                                    <>
                                        <Col>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                            >
                                                {t("tour.btn")}
                                            </Button>
                                        </Col>
                                        <Col
                                            xs={3}
                                            xl={2}
                                            className={`filter-btn ${
                                                filterCollapsed
                                                    ? "collapsed"
                                                    : "no-collapsed"
                                            }`}
                                        >
                                            {createElement(
                                                filterCollapsed
                                                    ? FilterFilled
                                                    : FilterOutlined,
                                                {
                                                    className: "trigger",
                                                    ref: refFilter,
                                                    onClick: () =>
                                                        setFilterCollapsed(
                                                            !filterCollapsed,
                                                        ),
                                                },
                                            )}
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Header>
                        <Content
                            className=""
                            style={{
                                margin: "24px 16px",
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
                            {process.env.NODE_ENV !== "production" && (
                                <>
                                    <RenderState />
                                    <br />
                                </>
                            )}
                            <Outlet />
                        </Content>
                        {contextHolder}
                        <Footer className="site-layout-background">
                            <Row justify={"space-around"}>
                                <Col>
                                    <Tooltip
                                        title={`buildTime: ${dataVersion.buildTime} - shortCommit: ${dataVersion.shortCommit} `}
                                    >
                                        <Text
                                            style={{
                                                color: "rgba(255, 255, 255, 0.65)",
                                                textAlign: "center",
                                                cursor: "pointer",
                                                display: "block",
                                            }}
                                            onClick={(e) => {
                                                copyToClipBoard(e, dataVersion);
                                            }}
                                        >
                                            {`${t("app.footer.version")}: ${
                                                dataVersion.version
                                            }`}
                                        </Text>
                                    </Tooltip>
                                </Col>
                                <Col>
                                    <Tooltip
                                        title={`buildTime: ${BUILD_TIME} - shortCommit: ${SHORT_COMMIT} `}
                                    >
                                        <Text
                                            style={{
                                                color: "rgba(255, 255, 255, 0.65)",
                                                textAlign: "center",
                                                cursor: "pointer",
                                                display: "block",
                                            }}
                                            onClick={(e) => {
                                                copyToClipBoard(e, {
                                                    version: VERSION,
                                                    shortCommit: SHORT_COMMIT,
                                                    longCommit: LONG_COMMIT,
                                                    buildTime: BUILD_TIME,
                                                });
                                            }}
                                        >
                                            {`${t(
                                                "app.footer.page",
                                            )}: ${VERSION}`}
                                        </Text>
                                    </Tooltip>
                                </Col>
                                <Col lg={4}>
                                    <Select
                                        style={{ width: "100%" }}
                                        options={options.map((option) => ({
                                            ...option,
                                            label: option.label,
                                        }))}
                                        placeholder={selectedOption?.label}
                                        value={
                                            selectedOption
                                                ? selectedOption.value
                                                : undefined
                                        }
                                        onChange={(value, option) => {
                                            handleLanguageChange(value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Footer>
                        <Tour
                            open={open}
                            onClose={() => setOpen(false)}
                            steps={steps}
                            scrollIntoViewOptions={false}
                            onChange={(current: number) => {
                                localStorage.setItem(
                                    "step",
                                    current.toString(),
                                );
                            }}
                        />
                    </Layout>

                    {filterEnabled && (
                        <Sider
                            collapsedWidth={0}
                            width={300}
                            trigger={null}
                            collapsible
                            collapsed={filterCollapsed}
                            className="site-layout-background filter-aside"
                        >
                            {filterComponent}
                        </Sider>
                    )}
                </Layout>
            </Formik>
        </>
    );
};

export default AppLayout;
