import { DeleteOutlined } from "@ant-design/icons";
import {
    Alert,
    Button,
    Col,
    Form,
    Input,
    Modal,
    Popconfirm,
    Row,
    message,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RocketApi from "../../../utils/api/rocket-api";

type ModalAliasProps = {
    open: boolean;
    onClose: (value: boolean) => void;
    rowData: any;
    editing: boolean;
    reloadTable: any;
};

const ModalAlias = ({
    open,
    onClose,
    rowData,
    editing,
    reloadTable,
}: ModalAliasProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [openPop, setOpenPop] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        if (open && editing) {
            form.setFieldsValue({
                alias: rowData.extra.alias,
            });
        }
    }, [open, rowData]);

    const onFinish = (values: any) => {
        const updatedValues = {
            id: rowData.id,
            resource_type: rowData.resource_type,
            alias: values.alias,
        };
        createAlias(updatedValues);
    };

    const createAlias = async (values: any): Promise<void> => {
        try {
            setLoading(true);
            const api = new RocketApi();
            const response = await api.request(`${rowData.cloud}/aliases`, {
                body: JSON.stringify(values),
            });
            setLoading(false);
            if (response?.status === 200) {
                form.resetFields();

                onClose(false);
                setOpenPop(false);
                setConfirmLoading(false);

                messageApi.open({
                    type: "success",
                    content: `${t("dashboard.usages.modals.alias.title")} ${
                        editing ? t("shared.edited") : t("shared.created")!
                    }`,
                });
                reloadTable(true);
            } else {
                setOpenPop(false);
                setConfirmLoading(false);
                messageApi.open({
                    type: "error",
                    content: `${
                        editing
                            ? t(
                                  "dashboard.usages.modals.alias.formValidations.messages.errorEdit",
                              )
                            : t(
                                  "dashboard.usages.modals.alias.formValidations.messages.errorCreate",
                              )
                    }`,
                });
            }
        } catch (err) {
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.alias.formValidations.messages.errorDelete",
                ),
            });
            onClose(false);
            setOpenPop(false);
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        onClose(false);
        form.resetFields();
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                onFinish(values);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handleDelete = async (): Promise<void> => {
        const api = new RocketApi();
        setConfirmLoading(true);

        const updatedValues = {
            id: rowData.id,
            resource_type: rowData.resource_type,
            alias: null,
        };
        const response = await api.request(`${rowData.cloud}/aliases`, {
            body: JSON.stringify(updatedValues),
        });

        form.resetFields();

        if (response?.status === 200) {
            setLoading(false);
            setConfirmLoading(false);
            setOpenPop(false);

            messageApi.open({
                type: "success",
                content: t(
                    "dashboard.usages.modals.alias.formValidations.messages.successDelete",
                ),
            });
        } else {
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.alias.formValidations.messages.errorDelete",
                ),
            });
        }
        setTimeout(() => {
            onClose(false);
            reloadTable(true);
        }, 100);
    };

    const showPopconfirm = () => {
        setOpenPop(true);
    };

    const handleCancelDelete = () => {
        setOpenPop(false);
    };

    return (
        <>
            {contextHolder}

            <Modal
                title={`${t("dashboard.usages.modals.alias.title")} ${
                    editing ? t("shared.edited") : t("shared.created")!
                }`}
                open={open}
                key={`modal_${rowData.id}`}
                className="modal-alias"
                onCancel={handleCancel}
                onOk={handleOk}
                footer={[
                    editing && (
                        <Popconfirm
                            title={t("formValidations.messages.areUSure")}
                            description={t(
                                "dashboard.usages.modals.alias.formValidations.messages.confirmDelete",
                            )}
                            open={openPop}
                            onConfirm={handleDelete}
                            okButtonProps={{ loading: confirmLoading }}
                            onCancel={handleCancelDelete}
                        >
                            <Button
                                key="delete"
                                danger
                                onClick={showPopconfirm}
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    ),
                    <Button key="back" onClick={handleCancel}>
                        {t("shared.cancel")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        loading={loading}
                    >
                        {editing ? t("shared.edit") : t("shared.create")}
                    </Button>,
                ]}
            >
                {rowData && (
                    <>
                        <Alert
                            message={t(
                                "dashboard.usages.modals.alias.messages.alertCreate",
                            )}
                            type="info"
                            showIcon
                            style={{ marginBottom: 15 }}
                        />
                        <Form
                            form={form}
                            layout="vertical"
                            name="form"
                            className="form-alias"
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t(
                                            "dashboard.usages.modals.alias.title",
                                        )}
                                        name="alias"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "dashboard.usages.modals.alias.formValidations.messages.aliasRequired",
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
            </Modal>
        </>
    );
};

export default ModalAlias;
