import "antd/dist/reset.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Navigate,
    Outlet,
    Route,
    Routes,
    useSearchParams,
} from "react-router-dom";

import "./App.css";
import AuthZ from "./components/authz";
import NotFound from "./components/ui/notFound";
import AppLayout from "./layouts/App";
import AuthLayout from "./layouts/Auth";
import AwsLayout from "./layouts/Aws";
import AzureLayout from "./layouts/Azure";
import AzureDWLayout from "./layouts/AzureDW";
import GcpLayout from "./layouts/Gcp";
import IbmLayout from "./layouts/Ibm";
import Admin from "./pages/Admin/Admin";
import Commands from "./pages/Admin/Commands";
import Markups from "./pages/Admin/Markups";
import Users from "./pages/Admin/Users";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import LoginOrSignup from "./pages/Authentication/LoginOrSignup";
import Dashboard from "./pages/Dashboard/Dashboard";
import Introduction from "./pages/Dashboard/Introduction";
import Mock from "./pages/Dashboard/Mock";
import Settings from "./pages/Settings/Settings";

const App = () => {
    const [searchParams] = useSearchParams();
    const { i18n } = useTranslation();

    useEffect(() => {
        const embedParam = searchParams.get("embed");
        if (embedParam) {
            localStorage.setItem("embed", embedParam);
        }

        const embed = localStorage.getItem("embed");

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "hack";
        script.src = `${process.env.PUBLIC_URL}/hack.js`;

        if (embed == "false") {
            const element = document.querySelector("script#hack");
            if (element) {
                document.body.removeChild(element);
            }
        } else if (embed === "true") {
            document.body.appendChild(script);
        }
    }, []);

    useEffect(() => {
        const lang = searchParams.get("lang") || localStorage.getItem("lang");
        if (lang) {
            i18n.changeLanguage(lang);
            localStorage.setItem("lang", lang);
        }
    }, [searchParams.get("lang"), localStorage.getItem("lang"), i18n]);

    return (
        <Routes>
            <Route path="/" element={<AuthLayout />}>
                <Route index element={<Navigate to="/login" />} />
                <Route path="login" element={<LoginOrSignup />} />
                <Route path="register" element={<LoginOrSignup />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route
                    path="forgot-password/:id"
                    element={<ForgotPassword />}
                />
            </Route>

            <Route
                path="/app"
                element={
                    <AuthZ>
                        <AppLayout />
                    </AuthZ>
                }
            >
                <Route index element={<Introduction />} />

                <Route
                    path="azure"
                    element={
                        <AuthZ features={["azure"]}>
                            <AzureLayout />
                        </AuthZ>
                    }
                >
                    <Route index element={<Dashboard />} />
                </Route>

                <Route
                    path="azuredw"
                    element={
                        <AuthZ features={["azuredw"]}>
                            <AzureDWLayout />
                        </AuthZ>
                    }
                >
                    <Route index element={<Dashboard />} />
                </Route>

                <Route
                    path="gcp"
                    element={
                        <AuthZ features={["gcp"]}>
                            <GcpLayout />
                        </AuthZ>
                    }
                >
                    <Route index element={<Dashboard />} />
                </Route>

                <Route
                    path="ibm"
                    element={
                        <AuthZ features={["ibm"]}>
                            <IbmLayout />
                        </AuthZ>
                    }
                >
                    <Route index element={<Mock value="IBM Dashboard" />} />
                </Route>

                <Route
                    path="aws"
                    element={
                        <AuthZ features={["aws"]}>
                            <AwsLayout />
                        </AuthZ>
                    }
                >
                    <Route index element={<Dashboard />} />
                </Route>

                <Route
                    path="settings"
                    element={
                        <AuthZ>
                            {/* HACK: since we don't have a settings layout we are using Outlet directly */}
                            <Outlet />
                        </AuthZ>
                    }
                >
                    <Route index element={<Settings />} />
                </Route>

                <Route
                    path="admin"
                    element={
                        <AuthZ features={["admin"]}>
                            {/* HACK: since we don't have a Admin layout we are using Outlet directly */}
                            <Outlet />
                        </AuthZ>
                    }
                >
                    <Route index element={<Admin />} />

                    <Route path="users">
                        <Route index element={<Users />} />
                        <Route path=":id" element={<Users />} />
                    </Route>
                    <Route path="commands">
                        <Route index element={<Commands />} />
                    </Route>
                    <Route path="markups/:provider">
                        <Route
                            index
                            element={
                                <AuthZ
                                    features={[
                                        "azure",
                                        "azuredw",
                                        "aws",
                                        "gcp",
                                    ]}
                                >
                                    <Markups />
                                </AuthZ>
                            }
                        ></Route>
                        <Route
                            path="aws"
                            element={
                                <AuthZ features={["admin", "aws"]}>
                                    <Markups />
                                </AuthZ>
                            }
                        ></Route>
                        <Route
                            path="gcp"
                            element={
                                <AuthZ features={["admin", "gcp"]}>
                                    <Markups />
                                </AuthZ>
                            }
                        ></Route>
                    </Route>
                </Route>
                {/* Using path="*"" means "match anything", so this route
        acts like a catch-all for URLs that we don't have explicit
        routes for. */}
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default App;
