import { MailOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Tag,
    Tooltip,
    message,
    theme,
} from "antd";
import type { InputRef } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { InitialValues } from "../../../components/rocket/types";
import RocketApi from "../../../utils/api/rocket-api";

type ModalReportsProps = {
    open: boolean;
    onClose: (value: boolean) => void;
    reloadTable: any;
    filters: any;
};

const options: DefaultOptionType[] = [
    { value: "history", label: "History" },
    { value: "invoiced", label: "Invoiced" },
];

const ModalReports = ({
    open,
    onClose,
    reloadTable,
    filters,
}: ModalReportsProps) => {
    const [emailTags, setEmailTags] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [emailInputVisible, setEmailInputVisible] = useState(false);
    const [emailInputValue, setEmailInputValue] = useState("");
    const [reportType, setReportType] = useState("history")

    const [errorInput, setErrorInput] = useState(true);

    const formik = useFormikContext<InitialValues>();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();

    const emailInputRef = useRef<InputRef>(null);
    const editEmailInputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (emailInputVisible) {
            emailInputRef.current?.focus();
        }
    }, [emailInputVisible]);

    useEffect(() => {
        editEmailInputRef.current?.focus();
    }, [emailInputValue]);

    const handleCloseEmailTag = (removedTag: string) => {
        const newEmailTags = emailTags.filter((tag) => tag !== removedTag);
        setEmailTags(newEmailTags);
        setEmailInputValue("");
    };

    const showEmailInput = () => {
        setEmailInputVisible(true);
        setErrorInput(true);
    };

    const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailInputValue(e.target.value);
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)
        ) {
            setErrorInput(false);
        } else {
            setErrorInput(true);
        }
    };

    const handleEmailInputConfirm = () => {
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                emailInputValue,
            )
        ) {
            if (emailInputValue && emailTags.indexOf(emailInputValue) === -1) {
                setEmailTags([...emailTags, emailInputValue]);
            }
            setEmailInputVisible(false);
            setEmailInputValue("");
        } else {
            if (emailInputValue !== "") {
                messageApi.open({
                    type: "error",
                    content: t("formValidations.messages.validationEmail"),
                });
                setErrorInput(true);
            }
        }
    };

    const tagPlusStyle: React.CSSProperties = {
        background: token.colorBgContainer,
        borderStyle: "dashed",
    };

    const handleCancel = () => {
        onClose(false);
        form.resetFields();
        setEmailTags([]);
        setEmailInputValue("");
        setEmailInputVisible(false);
    };

    const onFinish = () => {
        const emailValues = emailTags;

        if (emailValues.length === 0 && emailInputValue.trim() === "") {
            messageApi.open({
                type: "error",
                content: t("formValidations.messages.requiredEmail"),
            });
            setErrorInput(true);
            return;
        }

        if (
            emailInputValue.trim() !== "" &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                emailInputValue,
            )
        ) {
            return;
        }

        const sendValues = {
            emails: emailValues,
            type: reportType,
            filters: {
                filters: filters,
            },
        };
        createReport(sendValues);
    };

    const createReport = async (values: any): Promise<void> => {
        try {
            setLoading(true);
            const api = new RocketApi();
            const data = await api.request(
                `${formik.values.settings.provider}/download/request/report`,
                {
                    body: JSON.stringify(values),
                },
            );
            setLoading(false);
            form.resetFields();
            setEmailTags([]);

            setLoading(false);
        } catch (err) {
            messageApi.open({
                type: "error",
                content: t("dashboard.reports.modal.messages.errorSend"),
            });
            console.log(err);
        } finally {
            form.resetFields();
            setEmailTags([]);
            messageApi.open({
                type: "success",
                content: t("dashboard.reports.modal.messages.successSend"),
            });
            setTimeout(() => {
                onClose(false);
                reloadTable();
            }, 800);
        }
    };

    // TODO: Options should come from backend
    const providerOptions = formik.values.settings.provider != "azuredw" ? [options[0]] : options

    const onSelectChange = (value: string) => {
        setReportType(value)
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={t("dashboard.reports.modal.title")}
                open={open}
                okText={t(
                    "dashboard.reports.modal.formValidations.inputs.send",
                )}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t("shared.cancel")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onFinish}
                        loading={loading}
                        disabled={errorInput ? true : false}
                    >
                        {t(
                            "dashboard.reports.modal.formValidations.inputs.send",
                        )}
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" name="form">
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Form.Item
                                label={
                                    <span>
                                        <MailOutlined
                                            style={{ marginRight: "5px" }}
                                        />
                                        {t("formValidations.inputs.emails")}
                                    </span>
                                }
                                tooltip={t(
                                    "dashboard.reports.modal.tooltips.email",
                                )}
                            >
                                {emailTags.map((tag, index) => {
                                    const isLongTag = tag.length > 20;
                                    const tagElem = (
                                        <Tag
                                            key={tag}
                                            closable={true}
                                            style={{ userSelect: "none" }}
                                            onClose={() =>
                                                handleCloseEmailTag(tag)
                                            }
                                        >
                                            <span>
                                                {isLongTag
                                                    ? `${tag.slice(0, 20)}...`
                                                    : tag}
                                            </span>
                                        </Tag>
                                    );
                                    return isLongTag ? (
                                        <Tooltip title={tag} key={tag}>
                                            {tagElem}
                                        </Tooltip>
                                    ) : (
                                        tagElem
                                    );
                                })}
                                {emailInputVisible ? (
                                    <Input
                                        ref={emailInputRef}
                                        type="email"
                                        size="large"
                                        value={emailInputValue}
                                        style={{
                                            width: 300,
                                            height: 30,
                                            borderColor: errorInput
                                                ? "red"
                                                : "",
                                        }}
                                        onChange={handleEmailInputChange}
                                        onBlur={handleEmailInputConfirm}
                                        onPressEnter={handleEmailInputConfirm}
                                    />
                                ) : (
                                    <Tag
                                        style={tagPlusStyle}
                                        onClick={showEmailInput}
                                    >
                                        <PlusOutlined /> {t("shared.new")}{" "}
                                        E-mail
                                    </Tag>
                                )}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        {t(
                                            "formValidations.inputs.reportType",
                                            "Report Type",
                                        )}
                                    </>
                                }
                                tooltip={t(
                                    "dashboard.reports.modal.tooltips.reportType",
                                    `Represents the kind of report to be downloaded`,
                                )}
                            >
                                <Select
                                    options={providerOptions}
                                    defaultValue="history"
                                    onChange={onSelectChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ModalReports;
