import { Form, Input, Modal, Tabs, TabsProps, notification } from "antd";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import { Profiles } from "./CreateUser";

const EditUser = (props: { refresh: () => void }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { client } = useAuth();
    const [isSubmiting, setIsSubmiting] = useState(false);

    const [form] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    setIsModalVisible(true);
                    const res = await client.get(`/users/${id}`);
                    if (res.status === 200) {
                        form.setFieldsValue(res.data);
                        setIsLoading(false);
                    }
                } catch (error) {
                    setIsModalVisible(false);
                    if (error instanceof AxiosError) {
                        notification.warning(error.response?.data);
                    }
                    navigate("/app/admin/users");
                }
            })();
        }

        return () => {
            setIsModalVisible(false);
        };
    }, [id, form, client, navigate]);

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                if (
                    !values.profiles ||
                    !values.profiles[0].provider ||
                    values.profiles.length === 0
                ) {
                    notification.open({
                        type: "error",
                        message: t("admin.users.modal.messages.profilesError"),
                    });
                    return false;
                }

                // FIX: backend is waiting an array
                if (values["profiles"]) {
                    values.profiles.map(
                        (profile: any) =>
                            (profile.resources = Array.isArray(
                                profile.resources,
                            )
                                ? profile.resources
                                : profile.resources.split(",")),
                    );
                }

                setIsSubmiting(true);

                await client
                    .put(`/users/${id}`, values)
                    .then(() => {
                        form.resetFields();
                        notification.success({
                            message: t(
                                "admin.users.modal.messages.successEdit",
                            ),
                        });
                        navigate("/app/admin/users", { replace: true });

                        setIsModalVisible(false);
                        props.refresh();
                        setIsSubmiting(false);
                        form.resetFields();
                    })
                    .catch((error) => {
                        console.error(error);
                        notification.error({
                            message: t("admin.users.modal.messages.errorEdit"),
                        });

                        console.error(error);
                        setIsSubmiting(false);
                    });
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsLoading(true);
        form.resetFields();
        navigate("/app/admin/users", { replace: true });
        props.refresh();
    };

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: t("admin.users.modal.tabs.userInfo"),
            children: (
                <>
                    <Form.Item name="id" label="#" hidden>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        name="username"
                        label={t("formValidations.inputs.userName")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "formValidations.messages.requiredUsername",
                                ),
                            },
                        ]}
                    >
                        <Input placeholder="John Doe" type="text" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: "email",
                                message: t(
                                    "formValidations.messages.validationEmail",
                                ),
                            },
                            {
                                required: true,
                                message: t(
                                    "formValidations.messages.requiredEmail",
                                ),
                            },
                        ]}
                    >
                        <Input placeholder="johndoe@company.com" type="email" />
                    </Form.Item>
                </>
            ),
        },
        {
            key: "2",
            label: t("admin.users.modal.tabs.roles"),
            children: <>Roles...</>,
            disabled: true,
        },
        {
            key: "3",
            label: t("admin.users.tableTitles.profiles"),
            children: <Profiles />,
        },
    ];

    return (
        <Modal
            destroyOnClose
            title={`${t("shared.edit")} ${t("admin.users.title")}`}
            open={isModalVisible}
            confirmLoading={isSubmiting}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form form={form} layout="vertical">
                {isLoading ? (
                    <>Loading...</>
                ) : (
                    <Tabs defaultActiveKey="1" items={items} />
                )}
            </Form>
        </Modal>
    );
};

export default EditUser;
