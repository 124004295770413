import {
    CopyOutlined,
    DownOutlined,
    EditOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Dropdown,
    Empty,
    MenuProps,
    Progress,
    Skeleton,
    Space,
    Table,
    Tooltip,
    message,
    theme,
} from "antd";
import type { TableProps } from "antd";
import { ColumnType, ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InitialValues, KeyValuePair } from "../../../components/rocket/types";
import useNamedRef from "../../../hooks/useNamedRef";
import RocketApi from "../../../utils/api/rocket-api";
import download from "../../../utils/download";
import formatCurrency from "../../../utils/formatCurrency";
import ModalAlerts from "./modalAlerts";
import ModalAlias from "./modalAlias";
import ModalMarkups from "./modalMarkups";
import UsageCardTitle from "./usageCardTitle";

const { useToken } = theme;

export type SkeletonTableColumnsType = {
    key: string;
};
interface DataItem {
    id: string;
    name: string;
    last: number;
    actual: number;
    forecast: number;
    parentId: string;
    extra: any;
}

const UsageDetails = () => {
    const formik = useFormikContext<InitialValues>();
    const { token } = useToken();
    const { t, i18n } = useTranslation();
    const refUsages = useNamedRef("usages");

    const refDownloadUsages = useNamedRef("downloadUsages");
    const [usages, setUsages] = useState([]);
    const [alerts, setAlerts] = useState<{ [key: string]: any }>({});

    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingAlerts, setLoadingAlerts] = useState(false);
    const [editing, setEditing] = useState(false);
    const [localFiltersTable, setLocalFiltersTable] = useState<KeyValuePair[]>(
        [],
    );
    const [isCopied, setIsCopied] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [visibleMarkups, setVisibleMarkups] = useState(false);
    const [visibleAlertsFraud, setVisibleAlertsFraud] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalMarkupsVisible, setIsModalMarkupsVisible] = useState(false);
    const [isModalAliasVisible, setIsModalAliasVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const [initialRender, setInitialRender] = useState(true);

    const [sortedInfo, setSortedInfo] = useState<SorterResult<DataItem>>({
        order: "descend",
        columnKey: "actual",
    });
    const [forecastHidden, setForecastHidden] = useState(false);

    const [current, setCurrent] = useState(1);

    //@ts-ignore
    const user = JSON.parse(localStorage.getItem("USER"));
    const profiles = user ? user.profiles : [];

    // TODO: Change formik type
    const filtersPerLevel: string[] = formik.values.settings.frontend.filters;
    const markupView: string | null = formik.values.settings.markupView;
    const estimateView: string | null = formik.values.settings.estimateView;
    const aliasesView: string | null = formik.values.settings.aliasesView;
    const aliasId: string[] = formik.values.settings.frontend.aliases;

    // TODO: Change formik type
    const levels: string[] = formik.values.settings.frontend.views;

    useEffect(() => {
        // @ts-ignore
        setForecastHidden(localStorage.getItem("isCurrent") !== "true");
    }, [localStorage.getItem("isCurrent")]);

    useEffect(() => {
        profiles.forEach((profile: { resource_type: string }) => {
            if (profile.resource_type === "distributors") {
                setVisibleMarkups(true);
            }
        });
    }, []);

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }
        if (formik.values.settings.provider === "aws") {
            const params = new URLSearchParams(window.location.search);
            for (const [key, value] of params) {
                if (key === "margin") {
                    filters.push({ key, value });
                }
            }
        }
        setLocalFiltersTable(filters);
    }, [formik.values.filters]);

    useEffect(() => {
        const controller = new AbortController();
        let isMounted = true;

        const fetchDataAndAlerts = async () => {
            try {
                setLoadingTable(true);
                setLoadingAlerts(true);

                const api = new RocketApi();

                const responseAlerts = await api.request("alerts", {
                    method: "GET",
                    controller,
                });
                const alertsData = await responseAlerts?.json();
                if (alertsData.items) {
                    const filterAlerts = alertsData.items.filter((a: any) => {
                        return (
                            a.provider === formik.values.settings.provider &&
                            // @ts-ignore
                            a.resourceType === formik.values.filters.view
                        );
                    });
                    let alertLoaded = {};
                    for (var i = 0; i < filterAlerts.length; i++) {
                        // @ts-ignore
                        alertLoaded[filterAlerts[i].resourceId.toLowerCase()] =
                            filterAlerts[i];
                    }
                    if (isMounted) {
                        setAlerts(alertLoaded);
                        localStorage.setItem(
                            "alerts",
                            JSON.stringify(alertLoaded),
                        );
                        // @ts-ignore
                        setVisibleAlertsFraud(
                            estimateView === formik.values.filters.view,
                        );
                    }
                }
                const response = await api.request(
                    `${formik.values.settings.provider}/usages`,
                    {
                        body: JSON.stringify({ filters: localFiltersTable }),
                        controller,
                    },
                );

                const usagesData = await response?.json();

                if (isMounted) {
                    setLoadingTable(false);
                    setLoadingAlerts(false);
                    setUsages(usagesData);
                    setCurrent(1);
                    setInitialRender(false);
                }
            } catch (err) {
                console.log(err);
            } finally {
                if (isMounted) {
                    setLoadingAlerts(false);
                    setLoadingTable(false);
                }
            }
        };

        if (localFiltersTable.length > 0) {
            fetchDataAndAlerts();
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [localFiltersTable]);

    useEffect(() => {
        if (visibleAlertsFraud) {
            setSortedInfo({
                order: "descend",
                columnKey: "fraudAlerts",
            });
        } else {
            setSortedInfo({
                order: "descend",
                columnKey: "actual",
            });
        }
    }, [visibleAlertsFraud]);

    const showModal = (e: any, row: any, editing: boolean, type: string) => {
        e.preventDefault();
        e.stopPropagation();

        if (type === "alerts") {
            let filters: KeyValuePair[] = [];
            for (const key in formik.values.filters) {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }

            const alerts = {
                cloud: formik.values.settings.provider,
                id: row.id,
                name: row.name,
                // @ts-ignore
                view: formik.values.filters.view,
                filters: filters,
            };
            setSelectedRow(alerts);
            setIsModalVisible(true);
        } else if (type === "markups") {
            const markups = {
                cloud: formik.values.settings.provider,
                id: row.id,
                name: row.name,
                // @ts-ignore
                view: formik.values.filters.view,
            };
            setSelectedRow(markups);
            setIsModalMarkupsVisible(true);
        } else {
            const data = {
                id: row.id,
                resource_type: row.resource_type,
                extra: row.extra,
                cloud: formik.values.settings.provider,
            };
            setSelectedRow(data);
            setIsModalAliasVisible(true);
        }
        setEditing(editing);
    };

    const reloadTableActions = (isAlias: Boolean) => {
        const controller = new AbortController();
        fetchDataAndAlerts(controller, isAlias);
        return () => controller.abort();
    };

    const fetchDataAndAlerts = async (
        controller: AbortController,
        isAlias: Boolean,
    ): Promise<void> => {
        try {
            setLoadingTable(true);
            setLoadingAlerts(true);

            const api = new RocketApi();

            const responseAlerts = await api.request("alerts", {
                method: "GET",
                controller,
            });
            const alertsData = await responseAlerts?.json();

            if (alertsData.items) {
                const filterAlerts = alertsData.items.filter((a: any) => {
                    return (
                        a.provider === formik.values.settings.provider &&
                        // @ts-ignore
                        a.resourceType === formik.values.filters.view
                    );
                });
                let alertLoaded = {};
                for (var i = 0; i < filterAlerts.length; i++) {
                    // @ts-ignore
                    alertLoaded[filterAlerts[i].resourceId.toLowerCase()] =
                        filterAlerts[i];
                }
                setAlerts(alertLoaded);
                localStorage.setItem("alerts", JSON.stringify(alertLoaded));
                setLoadingAlerts(false);
            }

            const response = await api.request(
                `${formik.values.settings.provider}/usages?ignore-cache=true`,
                {
                    body: JSON.stringify({ filters: localFiltersTable }),
                    controller,
                },
                isAlias,
            );
            const usagesData = await response?.json();
            setUsages(usagesData);
            setLoadingTable(false);
            setLoadingAlerts(false);
            setCurrent(1);
        } catch (err) {
            console.log(err);
        }
    };

    const renderAlertsTpl = (row: DataItem) => {
        const alertsLocal = JSON.parse(
            localStorage.getItem("alerts") as string,
        );

        const alertForResource =
            alertsLocal && alertsLocal[row.id.toLowerCase()];

        if (alertForResource === undefined || alertForResource === null) {
            return (
                <Button
                    key={`btn_${row.id}`}
                    type="primary"
                    onClick={(e) => showModal(e, row, false, "alerts")}
                >
                    {t("shared.new")}
                </Button>
            );
        } else {
            let minimumPercentage = 100;
            for (const percentage of alertForResource.percentages) {
                if (percentage < minimumPercentage) {
                    minimumPercentage = percentage;
                }
            }

            let color = "#1a9b1a";
            const p = (alertForResource.amount * minimumPercentage) / 100;
            let percentage = (row.actual * 100) / p;
            percentage = Math.round(percentage);
            if (percentage >= 90) {
                color = "#ff4c4c";
            }

            if (percentage < 90 && percentage >= 60) {
                color = "#e1ef08";
            }

            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Tooltip title={`${percentage}%`}>
                        <Progress
                            status="active"
                            strokeColor={color}
                            percent={percentage}
                            showInfo={false}
                            style={{ margin: 0 }}
                        />
                    </Tooltip>
                    <Button
                        type="text"
                        onClick={(e) => showModal(e, row, true, "alerts")}
                        icon={<EditOutlined />}
                    />
                </div>
            );
        }
    };

    function copyToClipBoard(e: any, record: any) {
        // só copia o id (se for pra ir pro proximo nivel é só rancar isso aqui)
        e.preventDefault();
        e.stopPropagation();

        navigator.clipboard
            .writeText(record.id)
            .then(() => {
                setIsCopied(true);
                messageApi.open({
                    type: "success",
                    content: t("shared.copied") + " " + record.id,
                });
                setTimeout(() => {
                    setIsCopied(false);
                }, 3000);
            })
            .catch((error) => {
                console.log("Error copying to clipboard:", error);
            });
    }

    function handleTableRowClick(record: any) {
        const { id, resource_type: resourceType } = record;

        const idx = levels.findIndex((level: string) => level === resourceType);
        const nextView =
            idx + 1 > levels.length ? levels[levels.length] : levels[idx + 1];
        const filterKey =
            idx > levels.length
                ? filtersPerLevel[levels.length]
                : filtersPerLevel[idx];

        if (idx + 1 >= levels.length) {
            return;
        }

        let currentAppliedFilters = formik.values.appliedFilters;
        currentAppliedFilters.push({
            id: record.id,
            name: record.name,
            view: resourceType,
        });

        formik.setFieldValue("appliedFilters", currentAppliedFilters);
        formik.setFieldValue("filters.view", nextView);
        formik.setFieldValue(`filters.${filterKey}`, id);
    }

    const handleSortChange: TableProps<DataItem>["onChange"] = (
        pagination,
        filters,
        sorter,
    ) => {
        setSortedInfo(sorter as SorterResult<DataItem>);
    };

    const getCorrespondingAliases = (view: string) => {
        const index = levels.indexOf(view);
        const parsedString = aliasId[index].replace("_", " "); // tenant_id => Tenant id
        return parsedString;
    };

    //@ts-ignore
    const actionsColumn: ColumnType<DataItem> =
        (visibleMarkups && markupView === formik.values.filters.view) ||
        aliasesView?.includes(formik.values.filters.view)
            ? {
                  //@ts-ignore
                  title: t("dashboard.usages.tableTitles.actions"),
                  dataIndex: "additionalActions",
                  key: "additionalActions",
                  render: (value, row, index) => {
                      const items: MenuProps["items"] = [
                          markupView === formik.values.filters.view
                              ? {
                                    key: "1",
                                    label: (
                                        <Button
                                            type="link"
                                            onClick={(e) =>
                                                showModal(
                                                    e,
                                                    row,
                                                    false,
                                                    "markups",
                                                )
                                            }
                                        >
                                            {t("dashboard.usages.btns.markups")}
                                        </Button>
                                    ),
                                }
                              : null,
                          aliasesView?.includes(formik.values.filters.view)
                              ? {
                                    key: "2",
                                    label: (
                                        <Button
                                            type="link"
                                            onClick={(e) =>
                                                showModal(
                                                    e,
                                                    row,
                                                    row.extra.alias
                                                        ? true
                                                        : false,
                                                    "alias",
                                                )
                                            }
                                        >
                                            {t(
                                                "dashboard.usages.btns.manageAlias",
                                            )}
                                        </Button>
                                    ),
                                }
                              : null,
                      ];

                      return (
                          <Dropdown menu={{ items }} trigger={["hover"]}>
                              <Button
                                  onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                  }}
                              >
                                  <Space>
                                      <DownOutlined />
                                  </Space>
                              </Button>
                          </Dropdown>
                      );
                  },
              }
            : null;

    const renderTitleAlertsColumn = () => {
        return (
            <Tooltip title={t("dashboard.usages.tooltips.alertsFraud")}>
                {t("dashboard.usages.tableTitles.alerts")}
            </Tooltip>
        );
    };

    //@ts-ignore
    const alertsColumn: ColumnType<DataItem> = visibleAlertsFraud
        ? {
              title: renderTitleAlertsColumn,
              dataIndex: "extra",
              key: "fraudAlerts",
              sorter: (a, b) => {
                  const aValue =
                      a?.extra?.estimate !== 0 && a?.actual !== 0
                          ? (a?.actual * 100) / a?.extra?.estimate
                          : 0;
                  const bValue =
                      b?.extra?.estimate !== 0 && b?.actual !== 0
                          ? (b?.actual * 100) / b?.extra?.estimate
                          : 0;
                  return aValue - bValue;
              },
              width: "15%",
              sortOrder:
                  sortedInfo.columnKey === "fraudAlerts"
                      ? sortedInfo.order
                      : null,
              render: (value, row) => {
                  const total = row.actual;
                  const estimate = value?.estimate;
                  let color = "#1a9b1a";
                  const finalValue =
                      estimate !== 0 && total !== 0
                          ? (total * 100) / estimate
                          : 0;
                  if (finalValue > 100) {
                      color = "#ff4c4c"; // vermelho
                  } else if (finalValue > 85 && finalValue <= 100) {
                      color = "#e1ef08"; // amarelo
                  } else {
                      color = "#1a9b1a"; // verde
                  }

                  const parsed = Math.round(finalValue).toFixed(2);
                  return value ? (
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                          }}
                      >
                          <Tooltip
                              title={`Estimate: ${estimate?.toLocaleString(
                                  "en-US",
                                  {
                                      style: "currency",
                                      currency: "USD",
                                      minimumFractionDigits: 2,
                                  },
                              )} - ${parsed}%`}
                          >
                              <Progress
                                  status="active"
                                  strokeColor={color}
                                  percent={finalValue}
                                  showInfo={false}
                                  style={{ margin: 0 }}
                              />
                          </Tooltip>
                      </div>
                  ) : (
                      <Tooltip title={`No estimate`}>
                          <Progress
                              status="active"
                              strokeColor={color}
                              percent={finalValue}
                              showInfo={false}
                              style={{ margin: 0 }}
                          />
                      </Tooltip>
                  );
              },
              showSorterTooltip: false,
              sortDirections: ["descend", "ascend"],
          }
        : null;

    //@ts-ignore
    const forecastColumn: ColumnType<DataItem> = !forecastHidden
        ? {
              title: t("shared.forecast"),
              dataIndex: "forecast",
              key: "forecast",
              render: (value, row, index) => {
                  return (
                      <span>
                          {formatCurrency(
                              formik.values.settings.currencyCode,
                              value,
                              i18n.language,
                          )}
                      </span>
                  );
              },
              sorter: (a, b) => a.forecast - b.forecast,
              sortOrder:
                  sortedInfo.columnKey === "forecast" ? sortedInfo.order : null,
          }
        : null;

    const columns: ColumnType<DataItem>[] = [
        {
            title: getCorrespondingAliases(formik.values.filters.view),
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            width: "20%",
            render: (value, record, index) => {
                return (
                    <span
                        id={record.id}
                        onClick={(e) => copyToClipBoard(e, record)}
                    >
                        {value.length > 15
                            ? value.substring(0, 15) + "..."
                            : value}
                        <CopyOutlined
                            style={{
                                marginLeft: 5,
                            }}
                        />
                    </span>
                );
            },
            sorter: (a, b) => a.id.localeCompare(b.id),
            sortDirections: ["descend", "ascend"],
            sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
        },
        {
            title: t("dashboard.usages.tableTitles.name"),
            dataIndex: "name",
            key: "name",
            ellipsis: true,
            width: "20%",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
            sortOrder:
                sortedInfo.columnKey === "name" ? sortedInfo.order : null,
            render: (value, record, index) => {
                return (
                    <span>
                        {record.extra.alias ? (
                            <>
                                <span>
                                    {value.length > 15
                                        ? value.substring(0, 15) + "..."
                                        : value}
                                </span>
                                <Tooltip title={`Alias: ${record.extra.alias}`}>
                                    <InfoCircleOutlined
                                        style={{
                                            verticalAlign: "super",
                                            width: 15,
                                        }}
                                    />
                                </Tooltip>
                            </>
                        ) : (
                            value
                        )}
                    </span>
                );
            },
        },
        {
            title: t("dashboard.usages.tableTitles.budgetAlert"),
            dataIndex: "alerts",
            key: "alerts",
            width: "10%",
            render: (value, row, index) => renderAlertsTpl(row),
        },
        ...(alertsColumn ? [alertsColumn] : []),
        {
            title: t("shared.last"),
            dataIndex: "last",
            key: "last",
            render: (value, row, index) => {
                return (
                    <span>
                        {formatCurrency(
                            formik.values.settings.currencyCode,
                            value,
                            i18n.language,
                        )}
                    </span>
                );
            },
            sorter: (a, b) => a.last - b.last,
            sortOrder:
                sortedInfo.columnKey === "last" ? sortedInfo.order : null,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: t("shared.actual"),
            dataIndex: "actual",
            key: "actual",
            render: (value, row, index) => {
                return (
                    <strong style={{ color: "#555" }}>
                        {formatCurrency(
                            formik.values.settings.currencyCode,
                            value,
                            i18n.language,
                        )}
                    </strong>
                );
            },
            sorter: (a, b) => a.actual - b.actual,
            sortOrder:
                sortedInfo.columnKey === "actual" ? sortedInfo.order : null,
        },
        ...(forecastColumn ? [forecastColumn] : []),
        ...(actionsColumn ? [actionsColumn] : []),
    ];

    return (
        <Card
            title={
                <UsageCardTitle
                    // @ts-ignore
                    view={formik.values.filters?.view}
                    // @ts-ignore
                    periodStart={formik.values.filters?.start_time}
                    // @ts-ignore
                    periodEnd={formik.values.filters.end_time}
                />
            }
            ref={refUsages}
            style={{ height: "550px" }}
            extra={
                <Button
                    type="primary"
                    ref={refDownloadUsages}
                    onClick={() =>
                        download(
                            "usages",
                            localFiltersTable,
                            formik.values.settings.provider!,
                            null,
                        )
                    }
                >
                    {t("shared.download")}
                </Button>
            }
            styles={{
                header: {
                    color: token.colorPrimary,
                    borderLeft: `8px solid ${token.colorPrimary}`,
                },
            }}
            className="custom-card-header"
        >
            {contextHolder}
            <Table<DataItem>
                bordered={false}
                loading={initialRender}
                dataSource={usages}
                rowKey={(row) => row.id + row.name}
                style={
                    {
                        "--hover-color": token.colorPrimary,
                    } as React.CSSProperties
                }
                pagination={{
                    defaultPageSize: 5,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    onChange(page, pageSize) {
                        setCurrent(page);
                    },
                    defaultCurrent: 1,
                    current: current,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            handleTableRowClick(record);
                        },
                    };
                }}
                onChange={handleSortChange}
                scroll={{ x: 1000 }}
                columns={
                    !loadingTable && !loadingAlerts
                        ? columns
                        : columns.map((column) => {
                              return {
                                  ...column,
                                  render: function renderPlaceholder() {
                                      return (
                                          <Skeleton
                                              key={column.key}
                                              title
                                              active={loadingTable}
                                              paragraph={false}
                                          />
                                      );
                                  },
                              };
                          })
                }
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t("shared.noData")}
                        />
                    ),
                }}
            />
            <ModalAlerts
                open={isModalVisible}
                onClose={setIsModalVisible}
                rowData={selectedRow}
                editing={editing}
                reloadTable={reloadTableActions}
                alerts={alerts}
            />
            <ModalMarkups
                open={isModalMarkupsVisible}
                onClose={setIsModalMarkupsVisible}
                rowData={selectedRow}
                editing={editing}
                markups={alerts}
                isAdmin={false}
                provider={null}
            />
            <ModalAlias
                open={isModalAliasVisible}
                onClose={setIsModalAliasVisible}
                rowData={selectedRow}
                editing={editing}
                reloadTable={reloadTableActions}
            />
        </Card>
    );
};

export default UsageDetails;
