import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Input,
    InputRef,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
    notification,
} from "antd";
import type {
    ColumnsType,
    TablePaginationConfig,
    TableProps,
} from "antd/es/table";
import type {
    ColumnType,
    FilterConfirmProps,
    FilterValue,
    SorterResult,
} from "antd/es/table/interface";
import { AxiosError } from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";

const { Text } = Typography;

interface DataType {
    created_at: Date;
    deleted_at: Date | null;
    email: string;
    id: string;
    is_email_verified: boolean;
    updated_at: Date;
    username: string;
}
type DataIndex = keyof DataType;

const UserTable: FC<{
    data: any;
    pagination: TablePaginationConfig;
    loading: boolean;
    handleTableChange: TableProps<DataType>["onChange"];
    refresh: () => void;
}> = (props) => {
    const navigate = useNavigate();
    const { client } = useAuth();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef<InputRef>(null);
    const { t } = useTranslation();

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex,
    ): ColumnType<DataType> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`${t("admin.users.btns.search")} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex,
                        )
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex,
                            )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("admin.users.btns.search")}
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("admin.users.btns.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        {t("admin.users.btns.filter")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("admin.users.btns.close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? "#1677ff" : undefined }}
            />
        ),

        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (searchedColumn === dataIndex ? text : text),
    });

    const handleDelete = async (id: string) => {
        try {
            await client.delete(`/users/${id}`).then(() => {
                notification.success({
                    message: t("admin.users.messages.successDelete"),
                });
            });
        } catch (error) {
            if (error instanceof AxiosError) {
                // TODO: notification
                console.error({ error });
            }
        } finally {
            props.refresh();
        }
    };

    const handleEdit = (id: string) => {
        navigate(`/app/admin/users/${id}`);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "#",
            key: "id",
            dataIndex: "id",
            width: "20%",
            sorter: (a, b) => a.id.localeCompare(b.id),
            sortDirections: ["descend", "ascend"],
            ...getColumnSearchProps("id"),
        },
        {
            title: t("formValidations.inputs.userName"),
            key: "username",
            dataIndex: "username",
            ...getColumnSearchProps("username"),
            sorter: (a, b) => a.username.localeCompare(b.username),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "E-mail",
            key: "email",
            dataIndex: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ["descend", "ascend"],
            ...getColumnSearchProps("email"),
        },
        {
            title: t("admin.users.tableTitles.profiles"),
            key: "profiles",
            dataIndex: "profiles",
            render: (data: any, record: any) => (
                <label>{data && data.length > 0 ? data.join(",") : "-"}</label>
            ),
        },
        {
            title: t("admin.users.tableTitles.accessKey"),
            key: "credentials",
            dataIndex: "credentials",
            render: (data: any, record: any) => (
                <label>{data ? data.access_key : "-"}</label>
            ),
        },
        {
            title: t("admin.users.tableTitles.actions"),
            key: "actions",
            width: "10%",
            dataIndex: "actions",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <Button onClick={() => handleEdit(record.id)}>
                        {t("shared.edit")}
                    </Button>
                    <Popconfirm
                        title={t("admin.users.messages.confirmDelete")}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("shared.yes")}
                        cancelText={t("shared.no")}
                    >
                        <Button danger>{t("shared.delete")}</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={props.data}
            rowKey={(row) => row.id}
            pagination={{
                ...props.pagination,
                hideOnSinglePage: true,
                showSizeChanger: false,
            }}
            loading={props.loading}
            onChange={props.handleTableChange}
        />
    );
};

const Users = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { t } = useTranslation();
    const { client } = useAuth();

    const [data, setData] = useState<any[]>();
    const [loading, setLoading] = useState(false);

    const [refreshIndex, setRefreshIndex] = useState(false);

    const [tableChangeProps, setTableChangeProps] = useState<{
        pagination: TablePaginationConfig;
        filters: Record<string, FilterValue | null>;
        sorter: SorterResult<DataType> | SorterResult<DataType>[];
    }>({
        pagination: { current: 1, pageSize: 20, total: 0 },
        filters: {},
        sorter: {},
    });

    const refresh = () => {
        setRefreshIndex((prev) => !prev);
    };

    const mapSortOrder = (sort: SorterResult<DataType>) => {
        if (!sort.field) return "";
        if (sort.order === "descend") {
            return `-${sort.field}`;
        } else {
            return `+${sort.field}`;
        }
    };

    useEffect(() => {
        const controller = new AbortController();

        setLoading(true);

        const filter: { $or?: Array<Record<string, { $like: string }>> } = {};

        if (tableChangeProps.filters) {
            filter.$or = Object.keys(tableChangeProps.filters).reduce(
                (acc, key) => {
                    const value = tableChangeProps.filters[key];
                    if (value !== null && typeof value !== "undefined") {
                        //@ts-ignore
                        acc.push({ [key]: { $like: value[0] } });
                    }

                    return acc;
                },
                [] as Array<Record<string, { $like: string }>>,
            );
        }

        const sort = Array.isArray(tableChangeProps.sorter)
            ? tableChangeProps.sorter
                  .map(mapSortOrder)
                  .filter((sortField) => sortField.length > 0)
                  .join(",")
            : tableChangeProps.sorter.field
            ? mapSortOrder(tableChangeProps.sorter)
            : "";

        const limit = tableChangeProps.pagination.pageSize
            ? tableChangeProps.pagination.pageSize.toString()
            : "20";

        // HACK: since antd uses 1 based page we need to decrease 1 from page bc offset is 0 based page
        const offset = String(
            ((tableChangeProps.pagination.current || 1) - 1) *
                (tableChangeProps.pagination.pageSize || 20),
        );

        const searchParams = new URLSearchParams({
            limit,
            offset,
            sort,
            filter: JSON.stringify(filter),
        });

        client
            .get(`/users?${searchParams.toString()}`, {
                signal: controller.signal,
            })
            .then(({ data }) => {
                setData(data.items);
                setTableChangeProps({
                    ...tableChangeProps,
                    pagination: {
                        ...tableChangeProps.pagination,
                        total: data.total,
                        // If we have filters we go back to page 1
                        current: tableChangeProps.filters
                            ? tableChangeProps.pagination.current
                            : 1,
                    },
                });
            })
            .catch((err) => {
                console.error(err);
                notification.error({
                    message: t("admin.users.messages.failedLoad"),
                });
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            controller.abort();
        };
    }, [
        refreshIndex,
        tableChangeProps.filters,
        tableChangeProps.sorter,
        tableChangeProps.pagination.current,
    ]);

    const handleTableChange: TableProps<DataType>["onChange"] = async (
        pagination,
        filters,
        sorter,
    ) => {
        setTableChangeProps({
            pagination,
            filters,
            sorter,
        });
    };

    return (
        <>
            <CreateUser
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                refresh={refresh}
            />
            <EditUser refresh={refresh} />
            <Row justify="space-between" align="middle">
                <Col>
                    <Text strong>{t("admin.users.title")}</Text>
                </Col>
                <Col>
                    <Tooltip title={t("errorPages.btns.reload")}>
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={() => refresh()}
                            style={{ marginRight: 10 }}
                        ></Button>
                    </Tooltip>

                    <Button
                        onClick={() => setIsModalVisible(true)}
                        type="primary"
                    >
                        {t("shared.create")}
                    </Button>
                </Col>
            </Row>
            <Divider />
            <UserTable
                pagination={tableChangeProps.pagination}
                data={data}
                loading={loading}
                handleTableChange={handleTableChange}
                refresh={() => refresh()}
            />
        </>
    );
};

export default Users;
