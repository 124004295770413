import { Card, Tabs, theme } from "antd";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PieChart from "../../../components/rocket/PieChart";
import PieDrillDown from "../../../components/rocket/PieDrillDown";
import { KeyValuePair } from "../../../components/rocket/types";
import VMTable from "../../../components/rocket/vmTable";
import useLocalStorageValue from "../../../hooks/useLocalStorage";
import useNamedRef from "../../../hooks/useNamedRef";
import RocketApi from "../../../utils/api/rocket-api";

const { useToken } = theme;

const ResourcePie = () => {
    const { token } = useToken();
    const formik = useFormikContext<any>();
    const { t } = useTranslation();

    const refResources = useNamedRef("resources");
    const refByResources = useNamedRef("byResources");
    const refByCategory = useNamedRef("byCategory");
    const refByRegion = useNamedRef("byRegion");
    const refByTags = useNamedRef("byTags");
    const refByPublisher = useNamedRef("byPublisher");
    const refByVms = useNamedRef("byVms");
    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
        undefined,
    );

    const key = useLocalStorageValue("step");
    useEffect(() => {
        switch (key) {
            case "15":
                setActiveTabKey("Resources");
                break;
            case "16":
                setActiveTabKey("byCategory");
                break;
            case "17":
                setActiveTabKey("Region");
                break;
            case "18":
                setActiveTabKey("byTags");
                break;
            case "19":
                setActiveTabKey("byPublisher");
                break;
            case "20":
                setActiveTabKey("byVMs");
                break;
            default:
                setActiveTabKey(undefined);
                break;
        }
    }, [key]);

    const [loadingResources, setLoadingResources] = useState(true);
    const [loadingVm, setLoadingVm] = useState(false);
    const [loadingTags, setLoadingTags] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingRegion, setLoadingRegion] = useState(true);
    const [loadingPublisher, setLoadingPublisher] = useState(true);
    const [byCategory, setByCategory] = useState<any[] | null>(null);
    const [resources, setResources] = useState<any[] | null>(null);
    const [vm, setVm] = useState<any[] | null>(null);
    const [byTags, setByTags] = useState<any[] | null>(null);
    const [byRegion, setByRegion] = useState<any[] | null>(null);
    const [byPublisher, setByPublisher] = useState<any[] | null>(null);
    const [isVisibleByPublisher, setIsVisibleByPublisher] = useState(false);
    const [localFiltersbyTags, setLocalFiltersbyTags] = useState<
        KeyValuePair[]
    >([]);

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }
        if (formik.values.settings.provider === "aws") {
            const params = new URLSearchParams(window.location.search);
            for (const [key, value] of params) {
                if (key === "margin") {
                    filters.push({ key, value });
                }
            }
        }
        setLocalFiltersbyTags(filters);
        if (formik.values.filters.catalog) {
            setIsVisibleByPublisher(
                formik.values.filters.catalog.includes("marketplace"),
            );
        }
    }, [formik.values.filters]);

    const limitChartData = (data: any, limit: number) => {
        if (data.length <= limit) {
            return data;
        }
        const sortedData = data.sort((a: any, b: any) => b.value - a.value);
        const topData = sortedData.slice(0, limit);
        const sumOthers = sortedData
            .slice(limit)
            .reduce((sum: any, item: any) => sum + item.value, 0);
        const othersData = { name: "Others", value: sumOthers };
        return [...topData, othersData];
    };

    const limitChartDataWithChildren = (data: any, limit: number) => {
        if (data.length <= limit) {
            return data;
        }

        const sortedData = data.sort(
            (a: { value: number }, b: { value: number }) => b.value - a.value,
        );
        const topData = sortedData.slice(0, limit);
        const remainingData = sortedData.slice(limit);

        if (remainingData.length > limit) {
            const sumOfOthers = remainingData.reduce(
                (sum: any, item: { value: any }) => sum + item.value,
                0,
            );
            const othersData = {
                name: "Others",
                value: sumOfOthers,
                children: remainingData.slice(0, limit),
            };
            othersData.children = limitChartDataWithChildren(
                othersData.children,
                limit,
            );
            topData.push(othersData);
        } else {
            topData.push(...remainingData);
        }

        topData.forEach((item: { children: any }) => {
            if (item.children) {
                item.children = limitChartDataWithChildren(
                    item.children,
                    limit,
                );
            }
        });

        // Quando for 'Others'
        const othersIndex = topData.findIndex(
            (item: { name: string }) => item.name === "Others",
        );
        if (othersIndex !== -1) {
            const othersData = {
                name: "Others",
                value: sortedData
                    .slice(limit)
                    .reduce(
                        (sum: any, item: { value: any }) => sum + item.value,
                        0,
                    ),
                children: sortedData.slice(limit),
            };
            topData[othersIndex].children = limitChartDataWithChildren(
                othersData.children,
                limit,
            );
        }

        return topData;
    };

    const fetchDataResources = async (controller: AbortController) => {
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/usages`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                const formattedData = data
                    .map((obj: { actual: any; name: any }) => ({
                        ...obj,
                        value: obj.actual,
                        name: obj.name,
                    }))
                    .filter((obj: { value: number }) => obj.value !== 0);
                setResources(limitChartData(formattedData, 20));
            }
        } catch (err) {
            console.log(err);
            setResources(null);
        } finally {
            setLoadingResources(false);
        }
    };

    const fetchDataCategory = async (controller: AbortController) => {
        setByCategory([]);
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-category`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                const limitedData =
                    data?.children?.length > 0
                        ? limitChartDataWithChildren(data.children, 20)
                        : [];
                setByCategory(limitedData);
            } else {
                setByCategory(null);
            }
        } catch (err) {
            console.log(err);
            setByCategory(null);
        } finally {
            setLoadingCategories(false);
        }
    };

    const fetchDataVM = async (controller: AbortController) => {
        setVm([]);
        try {
            const api = new RocketApi();
            const provider = formik.values.settings.provider;
            let category;
            let additionalFilters: { key: string; value: string }[] = [];

            if (provider === "azure") {
                category = "Virtual Machines";
            } else if (provider === "gcp") {
                category = "Instance";
            } else if (provider === "aws") {
                category = "Amazon Elastic Compute Cloud";
                additionalFilters = [
                    {
                        key: "instance_type",
                        value: "true",
                    },
                ];
            }

            const filterVm = {
                filters: [
                    ...localFiltersbyTags.filter(
                        (filter) => filter.key !== "invoice_type",
                    ),
                    {
                        key: "category",
                        value: category,
                    },
                    ...additionalFilters,
                ],
            };

            const responsePromises = [
                api.request(`${provider}/by-resources`, {
                    body: JSON.stringify(filterVm),
                    controller,
                }),
            ];

            if (provider === "azure") {
                const filterReservedVm = {
                    filters: [
                        ...localFiltersbyTags.filter(
                            (filter) => filter.key !== "invoice_type",
                        ),

                        {
                            key: "category",
                            value: "Reserved_Instance",
                        },
                    ],
                };

                responsePromises.push(
                    api.request(`${provider}/by-resources`, {
                        body: JSON.stringify(filterReservedVm),
                        controller,
                    }),
                );
            }

            const responses = await Promise.all(responsePromises);

            const data =
                responses[0] && responses[0].status === 200
                    ? await responses[0].json()
                    : [];
            const reservedData =
                responses[1] && responses[1].status === 200
                    ? await responses[1].json()
                    : [];

            const combinedData = [...data, ...reservedData];
            setVm(combinedData);
        } catch (err) {
            console.error(err);
            setLoadingVm(false);
            setVm(null);
        } finally {
            setLoadingVm(false);
        }
    };

    const fetchDataTag = async (controller: AbortController) => {
        setByTags([]);
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-tag`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response) {
                const data = await response.json();
                if (response.status === 200) {
                    const limitedData =
                        data?.children?.length > 0
                            ? limitChartDataWithChildren(data.children, 20)
                            : [];
                    setByTags(limitedData);
                } else {
                    setByTags(null);
                }
            } else {
                setByTags(null);
            }
        } catch (err) {
            console.log(err);
            setByTags(null);
        } finally {
            setLoadingTags(false);
        }
    };

    const fetchDataRegion = async (controller: AbortController) => {
        setByRegion([]);
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-region`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                if (data) {
                    const formattedData = data
                        .map((obj: { value: any; label: any }) => ({
                            ...obj,
                            value: obj.value,
                            name: obj.label,
                        }))
                        .filter((obj: { value: number }) => obj.value !== 0);
                    setByRegion(limitChartData(formattedData, 20));
                } else {
                    // retorna null sem data
                    setByRegion(null);
                }
            } else {
                setByRegion(null);
            }
        } catch (err) {
            console.log(err);
            setByRegion(null);
        } finally {
            setLoadingRegion(false);
        }
    };

    const fetchDataPublisher = async (controller: AbortController) => {
        setByPublisher([]);
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-publisher`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                if (data) {
                    const formattedData = data
                        .map((obj: { value: any; label: any }) => ({
                            ...obj,
                            value: obj.value,
                            name: obj.label,
                        }))
                        .filter((obj: { value: number }) => obj.value !== 0);
                    setByPublisher(limitChartData(formattedData, 20));
                } else {
                    // retorna null sem data
                    setByPublisher(null);
                }
            } else {
                setByPublisher(null);
            }
        } catch (err) {
            console.log(err);
            setByPublisher(null);
        } finally {
            setLoadingPublisher(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (localFiltersbyTags.length > 0) {
            setLoadingResources(true);
            fetchDataResources(controller);

            setLoadingVm(true);
            fetchDataVM(controller);

            setLoadingCategories(true);
            fetchDataCategory(controller);

            setLoadingTags(true);
            fetchDataTag(controller);

            setLoadingRegion(true);
            fetchDataRegion(controller);

            if (isVisibleByPublisher) {
                setLoadingPublisher(true);
                fetchDataPublisher(controller);
            }
        }
        return () => controller.abort();
    }, [localFiltersbyTags]);

    const tabItems = useMemo(
        () => [
            {
                key: "Resources",
                tab: "By Resources",
                ref: refByResources,
                label:
                    t("dashboard.resourcePie.tabs.prefix") +
                    " " +
                    t(`shared.${formik.values.filters.view}`),
                children: (
                    <PieChart
                        theme={formik.values.settings.provider!}
                        data={resources || []}
                        type="usages"
                        loading={loadingResources}
                    />
                ),
                loading: loadingResources,
            },
            {
                key: "byCategory",
                tab: "By Category",
                ref: refByCategory,
                label:
                    t("dashboard.resourcePie.tabs.prefix") +
                    " " +
                    t("dashboard.resourcePie.tabs.category"),
                children: byCategory ? (
                    <PieDrillDown
                        theme={formik.values.settings.provider!}
                        data={byCategory}
                        loading={loadingCategories}
                        type="Categories"
                    />
                ) : null,
                loading: loadingCategories,
            },
            {
                key: "Region",
                ref: refByRegion,
                tab: "By Region",
                label:
                    t("dashboard.resourcePie.tabs.prefix") +
                    " " +
                    t("dashboard.resourcePie.tabs.region"),

                children: byRegion ? (
                    <PieChart
                        theme={formik.values.settings.provider!}
                        data={byRegion || []}
                        loading={loadingRegion}
                    />
                ) : null,
                loading: loadingRegion,
            },
            {
                key: "byTags",
                ref: refByTags,
                tab: "By Tags",
                label:
                    t("dashboard.resourcePie.tabs.prefix") +
                    " " +
                    t("dashboard.resourcePie.tabs.tags"),
                children: byTags ? (
                    <PieDrillDown
                        theme={formik.values.settings.provider!}
                        data={byTags}
                        type="Tags"
                        loading={loadingTags}
                    />
                ) : null,
                loading: loadingTags,
            },
            {
                key: "byPublisher",
                tab: "By Publisher",
                ref: refByPublisher,
                label:
                    t("dashboard.resourcePie.tabs.prefix") +
                    " " +
                    t("dashboard.resourcePie.tabs.publisher"),
                children:
                    isVisibleByPublisher && byPublisher ? (
                        <PieChart
                            theme={formik.values.settings.provider!}
                            data={byPublisher}
                            loading={loadingPublisher}
                        />
                    ) : null,
                loading: loadingPublisher,
            },
            {
                key: "byVMs",
                tab: "By VMs",
                ref: refByVms,
                label: `${t("dashboard.resourcePie.tabs.prefix")} VMs`,
                children: vm ? (
                    <VMTable
                        data={vm}
                        loading={loadingVm}
                        provider={formik.values.settings.provider!}
                    />
                ) : null,
            },
        ],
        [
            resources,
            byCategory,
            byTags,
            byPublisher,
            byRegion,
            vm,
            loadingResources,
            loadingCategories,
            loadingTags,
            loadingVm,
            formik.values.settings.provider,
            t,
        ],
    );

    const filteredTabItems = useMemo(
        () =>
            tabItems.filter(
                (item) => item.children !== null && item.children !== undefined,
            ),
        [tabItems],
    );

    return (
        <Card
            title={t("dashboard.resourcePie.title")}
            ref={refResources}
            styles={{
                header: {
                    color: token.colorPrimary,
                    borderLeft: `8px solid ${token.colorPrimary}`,
                },
            }}
            style={{ height: "100%" }}
        >
            {filteredTabItems.length > 0 && (
                <Tabs activeKey={activeTabKey}>
                    {tabItems.map((item) => (
                        <Tabs.TabPane
                            tab={<div ref={item.ref}>{item.label}</div>}
                            key={item.key}
                        >
                            <div>{item.children}</div>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            )}
        </Card>
    );
};

export default ResourcePie;
